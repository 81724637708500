import React from "react";

const Dots = ({ color }) => {
  return (
    <hr
      style={{
        borderTop: "dotted 1px",
        borderWidth: 0,
        borderTopWidth: "7px",
        margin: 0,
        padding: 0,
        width: "40%",
        borderColor: color,
        marginBottom: 35
      }}
    />
  );
};

export default Dots;
