import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import Columns from "../components/columns";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import BenefitBlock from "../components/benefitBlock";
import {
  FaBinoculars,
  FaPlus,
  FaMagnet,
  FaFilter,
  FaCalendar,
  FaBook,
  FaCrosshairs,
  FaWrench,
  FaChartLine, 
  FaUsers, 
  FaSearch, 
  FaClock,
  FaKey,
  FaCubes, 
  FaCogs,
  FaComments,
  FaExchangeAlt,
  FaCheckSquare,
  FaListOl

} from "react-icons/fa";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import TryButton from "../components/TryButton";
import Container from "../components/container";
import Lightbox from "../components/lightBox";
import Tile from "../components/Tile";
import { Box } from "@chakra-ui/core";
import styles from "./index.module.css";
import Dots from "../components/dots";


export const query = graphql`
{
  lap: file(
      relativePath: { eq: "nowy-laptop1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    narzedzia: file(
      relativePath: { eq: "crm-filozofia.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Crm = ({ data }) => {
  return (
    <FunctionPageLayout title="CRM - Wprowadzenie" headline="Filozofia działania oraz system wspierający Twój biznes">
      <SEO
        title="CRM – definicja, zastosowanie. – Czym jest system CRM?"
        description="CRM ✅ Co to jest CRM? Poznaj definicję CRM i jego zastosowanie w małym przedsiębiorstwie. Darmowy test bez żadnych zobowiązań."
        
      />
    <Container className="container container--text">
    <h1 style={{
      paddingBottom:20}}><strong>Definicja CRM</strong></h1>
    <p>
    <strong>CRM to skrót, za którym kryje się określenie z języka angielskiego: 
    customer relationship management.</strong> Dosłownie oznacza ono zarządzanie 
    relacjami z&nbsp;klientem. Nie zaspokaja to Twojej ciekawości? Aby w pełni zrozumieć 
    czym jest CRM, należy spojrzeć na ten termin z&nbsp;kilku różnych perspektyw.
    </p>
    <p>Przeszukując informacji o definicji CRM w&nbsp;Internecie można natrafić na 
    następujące, zaproponowane przez Ministerstwo Cyfryzacji sformułowanie:
    </p>
    <p><em><span>CRM to zestaw procedur i&nbsp;narzędzi istotnych w zarządzaniu 
    relacjami z&nbsp;klientem.</span></em>
    </p>
</Container>
<Container className="container container--text">
<div className="text-answers">
    <div className="text-block">
<p>
Tak krótka definicja nie odpowiada na wszystkie pytania, ale jest punktem 
wyjścia do dalszych rozważań. Wskazuje bowiem na dwoistość tego terminu, 
podkreślając dwa istotne wymiary – <strong>procedury i&nbsp;narzędzia.</strong></p>
<p>
Czytaj dalej a znajdziesz odpowiedź na następujące pytania:
</p>
<p><ul>
<li>Czym są procedury, a&nbsp;czym narzędzia opisane w definicji CRM?</li>
<li>Jak działa CRM i&nbsp;w &nbsp;aki sposób korzystać?</li>
<li>Jaka jest jego rola – jakie problemy pomoże rozwiązać?</li>
<li>Kto może skorzystać z jego możliwości?</li>
<li>Jakie funkcje i&nbsp;cechy są istotne przy jego wyborze?</li>
</ul></p>
</div>
    <div className="text-block">
          <Lightbox
                style={{
                  maxWidth: 1000,
                  margin: "0 auto",
                  paddingTop:20,
                }}
                images={[data.lap]}
                alts={["Laptop"]}
              />
        </div>
</div>
</Container>
<hr />
<h1 style={{
      paddingBottom:20}}><strong>Dwoistość terminu CRM</strong></h1>

<Container className="container container--text">
<div className="text-answers">
<div className="text-block">
<h3>CRM jako filozofia</h3>
<p>
To pierwszy, najbardziej podstawowy wymiar, w którym należy myśleć o&nbsp;CRM-ie. 
Jest to filozofia przedsiębiorstwa, wedle której wszystkie podejmowane działania 
mają jeden wspólny mianownik: <strong>satysfakcję klienta</strong>. Celem 
nadrzędnym prowadzenia działalności jest zaspokojenie jego potrzeb, które 
rozumie się jako ciągłe doskonalenie procesu zarządzania relacjami z&nbsp;klientami. 
Inaczej mówiąc, jest to <strong>zestaw procedur, które umożliwiają i&nbsp;wspierają 
zarządzanie relacjami z klientem.</strong>
</p>
<p>
Najważniejszymi częściami firmy są w&nbsp;tym przypadku działy sprzedaży i&nbsp;marketingu, 
jednak w proces budowania i&nbsp;utrzymywania relacji powinni angażować się wszyscy 
pracownicy mający kontakt z&nbsp;klientem (jak np. działy wsparcia czy reklamacji). 
Skupienie się na potrzebach klienta i&nbsp;relacji, jaką firma z nim tworzy wymaga 
szczególnego podejścia do wiedzy o&nbsp;kontrahentach. Powinna być 
ona <strong>usystematyzowana i&nbsp;uporządkowana</strong>, a&nbsp;łatwy dostęp do niej 
powinien mieć każdy (uprawniony) pracownik.
</p>
</div>
<div className="text-block">
<h3>CRM jako system</h3>
<p>
<strong>System CRM</strong> (z ang. CRM Systems) jest narzędziem, które pozwala 
firmie na szybkie i&nbsp;efektywne wdrożenie tej filozofii. Podstawowym jego 
zadaniem jest uporządkowanie procesów sprzedażowych. Systemy mogą różnić się 
pod wieloma względami. Najważniejszymi różnicami, które zazwyczaj występują 
między produktami są funkcjonalność, sposób wdrożenia i&nbsp;późniejsze wsparcie.
</p>
<p>
System CRM wspiera:
</p>
<p>
<ul>
<li>Pozyskiwanie nowego klienta</li>
<li>Rozwijanie współpracy z&nbsp;obecnymi klientami</li>
<li>Utrzymanie wysokiej jakości obsługi</li>
</ul></p>
<p>Zastanawiasz się nad wykorzystaniem systemu CRM w&nbsp;Twoim przedsiębiorstwie? 
Nie wiesz jakie elementy są kluczowe w&nbsp;jego funkcjonowaniu? Dowiedz się więcej 
o&nbsp;funkcjach, cechach i korzyściach, które przynosi program do zarządzania 
relacjami z klientami.
</p>
</div>
</div>
</Container>
<Container className="container container--text">
<p><em><span>Warto wiedzieć: realizacja strategii CRM jest możliwa dzięki 
korzystaniu z&nbsp;narzędzi informatycznych. Kluczem do jej wdrożenia jest połączenie 
tych dwóch dziedzin w&nbsp;każdym obszarze funkcjonowania firmy.
</span></em></p>
<Lightbox
                style={{
                  maxWidth: 600,
                  margin: "0 auto",
                  paddingTop:20,
                }}
                images={[data.narzedzia]}
                alts={["Laptop"]}
              />
</Container>
<hr />
<Container className="container container--text">
<h1 style={{paddingBottom:20}}><strong>Topowe funkcje CRM-a – co jest niezbędne?</strong></h1>
<p>
Podstawowym zadaniem oprogramowania klasy CRM jest <strong>koordynacja pracy 
działu handlowego</strong>, która ma przyczynić się do sprawniejszej 
i&nbsp;efektywniejszej obsługi potencjalnych oraz obecnych klientów. Aby zrealizować 
ten nadrzędny cel wpływający na inne istotne działania sprzedażowe CRM-y są 
najczęściej wyposażone w wiele podstawowych i dodatkowych funkcji. W&nbsp;przypadku 
rozwiązań dla małych firm bazowe opcje są zazwyczaj wystarczające. Należy jednak 
pamiętać o&nbsp;ich funkcjonalności i&nbsp;odpowiednim zaawansowaniu.
</p>
<p>
Poznaj kluczowe funkcje CRM-a i&nbsp;dowiedz się o ich zastosowaniu. Sprawdź jak 
wesprą pracę handlowców, menedżerów oraz kierowników.
</p> 
</Container>
<Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile to="/sprzedaz-w-crm/">
            <FaFilter size="70px" />
            <br />
            <h3>Zarządzanie szansami sprzedaży</h3>
            <p>
              Uporządkuj proces sprzedaży i&nbsp;monitoruj postępy swoich prac
            </p>
          </Tile>
          <Tile to="/kartoteki-kontrahentow/">
            <FaBook size="70px" />
            <br />
            <h3>Baza wiedzy o&nbsp;klientach</h3>
            <p>
              Gromadź najważniejsze informacje o&nbsp;swoich klientach i&nbsp;dbaj o&nbsp;długoterminowe relacje
            </p>
          </Tile>
          <Tile to="/kalendarz-i-zadania/">
            <FaCalendar size="70px" />
            <br />
            <h3>Planowanie i&nbsp;koordynacja pracy</h3>
            <p>
              Zaplanuj działania swoich pracowników i&nbsp;trzymaj rękę na pulsie
            </p>
          </Tile>
          <Tile to="/raporty/">
            <FaChartLine size="70px" />
            <br />
            <h3>Analiza działań sprzedaży</h3>
            <p>
              Analizuj wyniki w&nbsp;raportach systemowych i&nbsp;udoskonalaj proces sprzedaży
            </p>
          </Tile>
          </div>
          <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <Tile to="/pulpit-pracownika/">
            <FaWrench size="70px" />
            <br />
            <h3>Dynamiczny pulpit</h3>
            <p>
              Modyfikuj pulpit zgodnie z&nbsp;własnymi potrzebami i&nbsp;miej wszystkie niezbędne informacje pod ręką
            </p>
          </Tile>
          <Tile to="/komunikator/">
            <FaUsers size="70px" />
            <br />
            <h3>Komunikacja w&nbsp;zespole</h3>
            <p>
              Korzystaj z&nbsp;wbudowanego komunikatora i&nbsp;sprawnie przekazuj informacje swoich współpracownikom
            </p>
          </Tile>
          <Tile to="/zarzadzanie-uprawnieniami/">
            <FaKey size="70px" />
            <br />
            <h3>Zarządzanie uprawnieniami</h3>
            <p>
              Samodzielnie decyduj o&nbsp;dostępie do poszczególnych elementów systemu i&nbsp;dbaj o&nbsp;bezpieczeństwo danych
            </p>
          </Tile>
          </div>
</Container>
<hr />
<Container className="container container--text">
<h1 style={{paddingBottom:20}}><strong>Cechy istotne przy wyborze CRM–a</strong></h1>
<p style={{paddingBottom:20}}>
CRM to nie tylko funkcje. Są one, oczywiście, bardzo ważne, ale muszą współgrać 
z istotnymi cechami programu, które pozwolą na jego efektywne użytkowanie.
</p>
<BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaExchangeAlt size="45px" />}
          content="Mobilność:  Dostępność do systemu niezależnie od urządzenia i&nbsp;miejsca."
        />
        <BenefitBlock
          icon={<FaCogs size="45px" />}
          content="Elastyczność: Dostosowanie systemu do własnych potrzeb."
        />
        <BenefitBlock
          icon={<FaComments  size="45px" />}
          content="Wsparcie: Szybkie wdrożenie i&nbsp;materiały edukacyjne."
        />
        <BenefitBlock
          icon={<FaCheckSquare size="45px" />}
          content="Intuicyjność: Prostota obsługi konieczna w&nbsp;codziennych działaniach."
        />
      </BenefitBlockWrapper>

      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaPlus size="45px" />}
          content="Rozwój: Możliwość skorzystania z&nbsp;rozwiązania dedykowanego."
        />
        <BenefitBlock
          icon={<FaListOl size="45px" />}
          content="Brak limitów: Zero ograniczeń w&nbsp;prowadzonej działalności."
        />
        <BenefitBlock
          icon={<FaSearch size="45px" />}
          content="Demo: Test przed ostateczną decyzją."
        />
      </BenefitBlockWrapper>
<p>
Aby dowiedzieć się więcej o elementach kluczowych przy wyborze CRM-a przeczytaj nasz <Link to="/jaki-crm/">
              <strong>artykuł</strong>.
            </Link></p>
</Container>
<hr />
<Container className="container container--text">
<h1 style={{paddingBottom:20}}><strong> System CRM – kto może wykorzystać?</strong></h1>
<p>
Ze wsparcia systemu CRM może skorzystać <strong>każde przedsiębiorstwo, 
które potrzebuje pomocy w organizacji sprawnego procesu sprzedaży.</strong> Nie 
ma znaczenia wielkość firmy, ponieważ specyfika dobrych systemów CRM pozwala 
na dostosowanie rozwiązania do własnych potrzeb i&nbsp;prowadzonych działań 
sprzedażowych. Idealne dobranie programu sprawi, że spełni on swoją rolę 
i&nbsp;przyczyni się do oczekiwanych usprawnień biznesowych.
</p>
<p>
Wykorzystanie CRM-a w&nbsp;przedsiębiorstwie wpłynie na sposób działania całej 
organizacji, a także jej otoczenia. Z&nbsp;narzędzia skorzystają <strong>pracownicy, 
kierownictwo i&nbsp;osoby decyzyjne oraz klienci</strong>, którzy będą doświadczać 
wyższej jakości obsługi.
</p>
<section
      className={styles.pricingSection}
    >
      <div className="container container--text">
        <div className="pricing-model">
          <div
            style={{
              backgroundColor: "rgb(165, 185, 201)"
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/crm-dla-malej-firmy/"
            >
              <h2>PROSTY CRM</h2>
              <h4>dla małej firmy</h4>
            </Link>
          </div>
          <div
            style={{
              backgroundColor: "rgb(85, 155, 201)"
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/crm-dla-sredniej-firmy/"
            >
              <h2>PROSTY CRM</h2>
              <h4>dla średniej firmy</h4>
            </Link>
          </div>
          <div
            style={{
              backgroundColor: "rgb(20, 128, 201)"
            }}
          >
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/crm-dla-duzej-firmy/"
            >
              <h2>CRM</h2>
              <h4>dla dużej firmy</h4>
            </Link>
          </div>
        </div>
      </div>
    </section>
    </Container>
<hr />
<Container className="container container--text">
<h1 style={{paddingBottom:20}}><strong> Prosty CRM dla małej firmy</strong></h1>
<p>Jak zostało wspomniane wyżej, <strong>systemy CRM mogą różnić się pod względem 
poziomu zaawansowania.</strong> Niektóre z&nbsp;nich są projektowane z myślą o dużych 
firmach, które oczekują dedykowanych rozwiązań, a&nbsp;inne sprawdzą się w&nbsp;sektorze 
małych i&nbsp;średnich firm. Prosty CRM to taki, którego obsługa nie wymaga zdolności 
informatycznych. Nie powinno to jednak oznaczać, że posiada on ubogi zakres 
funkcji. Wśród opcji oferowanych przez prosty CRM powinny znaleźć się wszelkie 
podstawowe elementy przyczyniające się do usprawnień w obszarze zarządzania 
relacjami z klientami, które zostały przedstawione w funkcjach opisanych powyżej.
</p>
<p>
Dlaczego? Ponieważ <strong>podstawowym zadaniem CRM–u jest porządkowanie 
procesów sprzedażowych.</strong> W tym właśnie celu wykorzystuje się narzędzia 
CRM, takie jak zarządzanie szansami sprzedaży. Pozwala ono na monitorowanie 
i&nbsp;optymalizowanie funkcjonowania działu sprzedaży. Dobry CRM powinien umożliwiać 
także planowanie pracy zespołu sprzedażowego, a także porządkowanie wiedzy 
o klientach. Dodatkowym atutem będzie też dostęp do raportów, które pozwalają 
oszczędzać czas i&nbsp;dostarczają cennych informacji o efektach pracy Twojego zespołu.
</p>
<p><strong>Podstawowe funkcje muszą się znaleźć w&nbsp;każdym dobrym programie 
do zarządzania relacjami z klientami.</strong></p>
</Container>
<hr />
<Container className="container container--text">
<h1 style={{paddingBottom:20}}><strong>Rozwiąż swoje problemy i&nbsp;zdobądź korzyści</strong></h1>
</Container>
<Container className="container container--text">
<div className="text-answers">
<div className="text-block">
<h3>Jakie problemy rozwiąże CRM?</h3>
<p>System CRM rozwiąże wiele problemów Twojego przedsiębiorstwa – poznaj część 
z&nbsp;nich:
</p>
<p>
<ul>
<li>brak stałego dostępu do informacji o&nbsp;kliencie,</li>
<li>kłopoty ze sprawną koordynacją pracy zespołu,</li>
<li>częste zapominanie o&nbsp;ustaleniach z&nbsp;klientami i&nbsp;zaplanowanych działaniach,</li>
<li>trudności w przypadku nieobecności pracowników,</li>
<li>brak wiedzy o&nbsp;postępach,</li>
<li>problem z&nbsp;mierzeniem efektywności prowadzonych procesów,</li>
<li>obawa o&nbsp;bezpieczeństwo danych,</li>
<li>kłopoty ze sprawną komunikacją i&nbsp;przepływem informacji.</li>
</ul></p>
<p>
To tylko część problemów, z którymi możesz się spotykać na co dzień. Wykorzystaj 
CRM, pokonaj wszystkie trudności i&nbsp;zyskaj przewagę nad konkurencją.
</p>
</div>
<div className="text-block">
<h3>A co zyskasz?</h3>
<p>Wznieś swój biznes na wyższy poziom razem z CRM-em.</p>
<p>
<ul>
<li>Sprawdzaj informacje o&nbsp;kontrahentach w&nbsp;dedykowanych dla nich kartach systemowych.</li>
<li>Zaplanuj harmonogram swoich działań z&nbsp;wykorzystaniem Kalendarza i&nbsp;zadań.</li>
<li>Pamiętaj o&nbsp;&nbsp;staleniach z&nbsp;klientami dzięki systematycznie budowanej historii relacji.</li>
<li>Bądź na bieżąco z działaniami firmowymi korzystając z&nbsp;wewnętrznego komunikatora.</li>
<li>Monitoruj postępy szans sprzedaży.</li>
<li>Analizuj wyniki w&nbsp;raportach systemowych.</li>
<li>Przydzielaj uprawnienia poszczególnym pracownikom.</li>
<li>Z łatwością zastępuj pracowników dzięki informacjom zebranym w&nbsp;systemie.</li>
</ul></p>
</div>
</div>
</Container>
<hr />
<h1><strong>Więcej informacji o&nbsp;CRM-iem dla Ciebie</strong></h1>
<Container className="container container--text">
<div className="text-answers">
<div className="text-block">
<p>
<ul>
<li><Link to="/filozofia-crm/"><strong>Dowiedz się więcej o&nbsp;filozofii CRM.</strong></Link> </li>
<li><Link to="/system-crm/"><strong>Przeczytaj więcej o&nbsp;samym systemie CRM.</strong></Link></li>
<li><Link to="/pierwsze-kroki-w-crm/"><strong>Uzyskaj informacje o&nbsp;pierwszych krokach w CRM.</strong></Link></li> 
<li><Link to="/usprawnianie-procesu-sprzedazy/"><strong>Naucz się o&nbsp;procesie sprzedażowym.</strong></Link></li>
<li><Link to="/program-crm/"><strong>Przekonaj się o&nbsp;rozwoju z&nbsp;CRM-em.</strong></Link></li>
</ul>
</p>
</div>
<div className="text-block">
<p>
<ul>
<li><Link to="/mity-o-crm/"><strong>Oceń mity dotyczące CRM-a.</strong></Link></li>
<li><Link to="/aplikacja-crm/"><strong>Zapoznaj się z&nbsp;aplikacją CRM.</strong></Link></li>
<li><Link to="/proces-sprzedazy-w-crm/"><strong>Sprawdź więcej o&nbsp;szansach sprzedaży.</strong></Link></li>
<li><Link to="/crm-dla-malych-firm/"><strong>Przyjrzyj się głębiej relacjom z&nbsp;klientami.</strong></Link></li>
<li><Link to="/pomoc/"><strong>Zrozum obsługę QuickCRM.</strong></Link></li>
</ul>
</p>
</div>
</div>
</Container>
    </FunctionPageLayout>
  );
};

export default Crm;
